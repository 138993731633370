<script>
export default {
    name: 'NavComponent',
}
</script>

<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
        <div class="container-xxl">
            <button class="order-lg-3 navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <a class="order-lg-1 navbar-brand" href="/" width="112px">
                <img src="@/assets/img/LOGO.png" class="center">
            </a>
            <div class="order-lg-4 d-lg-inline d-none">
                <div class="row  align-items-center">
                    <div class="col" style="min-width: fit-content;max-width: fit-content;padding-right: 6px;">
                        <i class="fas fa-phone-alt fa-lg" style="min-width: 23px;"></i>
                    </div>
                    <div class="col" style="padding-left: 0px;padding-right: 0px;max-width: 142px;">
                        <div class="row">
                            <div class="col-12">
                                <h6 style="margin-bottom: 0px;  font-weight: bold;">Contact Us</h6>
                            </div>
                            <div class="col-12">
                                <a href="tel:+94761367821" class="link-dark" style="text-decoration: none;">+94 76 136
                                    7821</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="order-lg-4 d-inline d-lg-none"> <a href="tel:+94761367821" class="link-dark"><i
                        class="fas fa-phone fa-lg"></i></a></div>
            <div class=" order-lg-2 collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            Books
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li class="mt-2"><a class="dropdown-item" href="/books/sudu-minissu-1">Sudu Minissu 1</a>
                            </li>
                            <hr>
                            <li><a class="dropdown-item" href="/books/sudu-minissu-2">Sudu Minissu 2</a></li>
                            <hr>
                            <li><a class="dropdown-item" href="/books/sudu-minissu-3">Sudu Minissu 3</a></li>
                            <hr>
                            <li><a class="dropdown-item" href="/books/sudu-minissu-4">Sudu Minissu 4</a></li>
                            <hr>
                            <li><a class="dropdown-item" href="/books/sudu-minissu-5">Sudu Minissu 5</a></li>
                            <hr>
                            <li><a class="dropdown-item" href="/books/sudu-minissu-6">Sudu Minissu 6</a></li>
                            <hr>
                            <li><a class="dropdown-item" href="/books/minis-sunakhaya">Minis Sunakhaya</a>
                            </li>
                            <hr>
                            <li class="mb-2"><a class="dropdown-item" href="/books/maw-senehasa">Maw Senehasa</a>
                            </li>
                            <hr>
                            <li class="mb-2"><a class="dropdown-item" href="/books/sarwajana-washiya">Sarwajana Washiya</a>
                            </li>
                            <hr>
                            <li class="mb-2"><a class="dropdown-item" href="/books/sapalath-mawkusa">Sapalath Mawkusa</a>
                            </li>
                            <hr>
                            <li class="mb-2"><a class="dropdown-item" href="/books/dam-senevi">Dam Senevi</a>
                            </li>
                          <hr>
                            <li class="mb-2"><a class="dropdown-item" href="/books/vilapaya">Vilapaya</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link " aria-current="page" href="/shops">Where to buy</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>